import { render, staticRenderFns } from "./joinGroupMessage.vue?vue&type=template&id=44c3a66e&scoped=true"
import script from "./joinGroupMessage.vue?vue&type=script&lang=js"
export * from "./joinGroupMessage.vue?vue&type=script&lang=js"
import style0 from "./joinGroupMessage.vue?vue&type=style&index=0&id=44c3a66e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c3a66e",
  null
  
)

export default component.exports